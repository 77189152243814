import React from "react";

export default function Banner(props) {
  return (
    <div className="flex w-full py-6 rounded-xl lg:px-28 md:px-12 px-4 relative z-0">
      <img
        src={props.src}
        alt={props.alt ?? "banner image"}
        className={`w-full lg:h-[50vh] md:h-[350px] h-[300px] object-cover ${
          props.position ? props.position : "object-center"
        } rounded-xl`}
      ></img>
      {/* <div className="bg-lightBlue rounded-full w-40 h-40 absolute top-20 left-16 -z-1 opacity-60" /> */}
      <div className="bg-lightBlue rounded-full w-48 h-48 absolute -bottom-0 right-20 -z-1" />
    </div>
  );
}
