import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Banner from "../components/Banner";
import { FaCaretRight } from "@react-icons/all-files/fa/FaCaretRight";
import { technologyData } from "../data/technology-page";
export default function Technology() {
  return (
    <Layout>
      <Seo title="Technology" url="https://migrantresilience.org/technology" />
      <Banner src="/images/jansathi app (2) (1).jpg" position="object-center" />
      <div className="w-full flex flex-col justify-center items-start relative z-0 lg:px-28 md:px-12 px-4 overflow-hidden pb-20 pt-6 lg:pt-0">
        <h1 className="text-left lg:mt-4 mb-4 text-darkblue md:text-4xl text-2xl font-bold">
          Technology
        </h1>
        <p className="text-body md:text-base text-sm">
          The Jan Saathi mobile application and database, as well as the
          helpline call and redressal case management system, comprise the key
          technological infrastructure for MRC that help address some of these
          challenges.
        </p>
        <div className="flex flex-col w-full lg:gap-6 gap-12">
          {technologyData.map((technology, key) => (
            <div className="w-full" key={"tech" + key}>
              <p className="text-darkblue font-bold lg:text-center md:text-lg lg:text-2xl text-base leading-tight mt-8 mb-1">
                {technology.title}
              </p>
              <p className="text-darkblue font-normal md:text-base text-base leading-tight lg:text-center">
                {technology.subTitle}
              </p>
              <IconBox technology={technology} />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
export const IconBox = ({ technology }) => {
  const { description, gridTitle, columns, icon } = technology;
  return (
    <div className="flex flex-col w-full my-6">
      <div className="flex flex-row items-center gap-6 border-b border-dashed border-darkblue pb-2 mb-4">
        <img src={icon} alt={gridTitle} className="lg:w-40 w-20 h-auto" />
        <p>{description}</p>
      </div>
      <h3 className="bg-darkblue text-white p-2 text-left md:text-base text-sm font-bold">
        {gridTitle}
      </h3>
      <div className="flex lg:flex-row flex-col justify-between gap-8 w-full mt-2">
        {columns.map((data, key) => (
          <Column key={gridTitle + key} data={data} />
        ))}
      </div>
    </div>
  );
};
const Column = ({ caret = true, data }) => {
  const { title, list } = data;
  return (
    <div className="pointList flex flex-row items-center w-full relative 2xl:h-[350px] xl:h-[350px] lg:h-[325px] h-auto">
      <div className="flex flex-col w-full h-full border-[1px] border-black/20">
        <div className="py-4 px-2 bg-[#ECF0F8] lg:h-[70px] flex items-center justify-start 2xl:text-base lg:text-[1vw] text-sm text-black font-bold leading-tight">
          {title}
        </div>
        <div className="flex flex-col bg-white py-6 px-2">
          <ul className="list-none leading-tight lg:gap-3 gap-2 flex flex-col 2xl:text-base lg:text-[1vw] text-sm">
            {list.map((point, key) => (
              <li key={"list" + key + title}>- {point}</li>
            ))}
          </ul>
        </div>
      </div>
      {caret && (
        <FaCaretRight className="text-darkblue caret text-4xl absolute lg:-right-9 lg:top-1/2 lg:left-auto -bottom-9 left-[45%] lg:rotate-0 rotate-90" />
      )}
    </div>
  );
};
