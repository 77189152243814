export const technologyData = [
  {
    title: "Jan Saathi Application",
    subTitle: "MRC has custom-built a social registry for migrant workers.",
    description:
      "Jan Sathis carry an android phone with MRC application installed to facilitate registration, eligibility check, application, and verification of social security schemes and entitlements. The key features of the mobile app are listed below.",
    gridTitle: "Features of Jan Saathi App",
    icon: "/images/features.jpg",
    columns: [
      {
        title: "Identify and record target households (HH)",
        list: [
          "Assign target locations and track movement",
          "Track households that have already been registered",
          "Create a record for identified households",
        ],
      },
      {
        title: "Check HH's eligibility for programs",
        list: [
          "Record information to assess eligibility for social security schemes",
          "Recommend eligible schemes to the household based on pre-identified criteria",
          "Mark schemes and entitlements that HH wants to apply for",
        ],
      },
      {
        title: "Collect, correct, and apply for supporting documents",
        list: [
          "For the eligible schemes, provide a list of necessary documents",
          "Capture and store these documents",
          "Validate that the supporting documents meet necessary criteria",
        ],
      },
      {
        title: "Fill and submit application forms",
        list: ["Record valid application forms to be submitted offline"],
      },
      {
        title: "Track status of application",
        list: ["Manual update of the status of the application"],
      },
    ],
  },
  {
    title: "Helpline and Redressal Case Management System (CMS)",
    subTitle:
      "MRC’s CMS effectively addresses and tracks worker protection cases. The key stages of CMS are mentioned below:",
    description:
      "Create and track cases for the concerned person who contacts Jan Sahas team for any issue concerning social, cultural ill practices which affect their physical, mental, and biological stigma either through field representatives or by calling on the central helpline number.",
    gridTitle: "Stages of Helpline and Redressal CMS",
    icon: "/images/helpline.jpg",
    columns: [
      {
        title: "Complaint Received, Registered and Assigned",
        list: [
          "Complaint is received either through field representatives or through central helpline number",
          "Case is then registered for the concerned person in the system",
          "Assign the case to field officers according to the geographical area",
        ],
      },
      {
        title: "Investigation",
        list: [
          "Cases assigned to field officers, will verify the information provided while registering the case, is accurate.",
          "Recommend if legal recourse or arbitration is required based on the findings of the investigation.",
        ],
      },
      {
        title: "Enquiry",
        list: [
          "Prepare steps to be taken if legal action needs to be taken with help of legal counsel on the team",
          "Prepare plan if case needs to enter arbitration with help of worker protection team",
        ],
      },
      {
        title: "Evidence",
        list: [
          "Implement the plan prepared with supporting evidences to ensure a favorable decision for the complainant",
        ],
      },
      {
        title: "Decision",
        list: [
          "Based on completion of previous stages, the case will be closed with a reason/result. If further rehabilitation is required then it is provided to the complainant",
        ],
      },
    ],
  },
];
